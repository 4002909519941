import { useEffect } from 'react'
import './footer.sass'

const Footer = () => {
    useEffect(() => {
        // Fonction pour le défilement doux vers l'ancre
        function scrollToAnchor(targetId) {
            const targetElement = document.getElementById(targetId)
            if (targetElement) {
                const offset = targetElement.getBoundingClientRect().top
                const startPosition = window.pageYOffset
                const startTime = 'now' in window.performance ? performance.now() : new Date().getTime()
                function scroll() {
                    const currentTime = 'now' in window.performance ? performance.now() : new Date().getTime()
                    const elapsed = currentTime - startTime
                    const progress = Math.min(elapsed / 1000, 1)
                    window.scrollTo(0, startPosition + offset * easeInOutCubic(progress))
                    if (progress < 1) {
                        requestAnimationFrame(scroll)
                    }
                }
                // Démarrez l'animation de défilement doux
                requestAnimationFrame(scroll)
            }
        }
        // Fonction d'interpolation pour l'effet de défilement doux (ease-in-out cubic)
        function easeInOutCubic(t) {
            return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1
        }
        // Gérez le clic sur les liens de navigation pour le défilement doux
        function handleNavClick(event) {
            event.preventDefault()
            const targetId = event.currentTarget.getAttribute('href').substring(1) // Récupérez l'ID de la cible
            scrollToAnchor(targetId)
        }
        // Attachez un gestionnaire d'événements de clic à tous les liens de navigation
        const navLink = document.querySelectorAll('.footer__chevron')
        navLink.forEach((link) => {
            link.addEventListener('click', handleNavClick)
        })
    }, [])
    

    return (
        <footer className='footer'>
            <a className='footer__chevron' href='#about' aria-label='bouton accueil'><i className="fa-solid fa-chevron-up fa-2xl"></i></a>
            <div className='footer__links'>
                <a href="https://discord.gg/Tyn2xTj2c9" target='_blank' rel="noopener noreferrer" aria-label='Discord'><i className="fa-brands fa-discord"></i></a>
                <a href="mailto:contact@ibs.team" aria-label='MailTo'><i className="fa-solid fa-envelope"></i></a>
                <a href="https://twitter.com/IBSvalidator" target='_blank' rel="noopener noreferrer" aria-label='Twitter'><i className="fa-brands fa-twitter"></i></a>
            </div>
            <p className='footer__para'><strong>© SAS Inter Blockchain Services</strong></p>
        </footer>
    )
}
export default Footer