import axelar from '../assets/img/Axelar.jpg'
import quicksilver from '../assets/img/Quicksilver.png'
import kyve from '../assets/img/Kyve.png'
import okp4 from '../assets/img/OKP4.jpg'
import lava from '../assets/img/Lava.png'
import andromeda from '../assets/img/Andromeda.jpg'
import babylon from '../assets/img/Babylon.jpg'

export const testnetList = [
    {
        'id': 'zzza',
        'name': 'axelar',
        'imgSrc': axelar,
        'link': 'https://testnet.axelarscan.io/validator/axelarvaloper1qcypd94qgy6snm9srnazd42u4dn2gkexht59zy'
    },
    {
        'id': 'zzzb',
        'name': 'quicksilver',
        'imgSrc': quicksilver,
        'link': 'https://testnet.quicksilver.explorers.guru/validator/quickvaloper1tvudgqs9gghpya43cdqfslpp097uy4fsupkvz8'
    },
    {
        'id': 'zzzc',
        'name': 'kyve',
        'imgSrc': kyve,
        'link': 'https://explorer.kaon.kyve.network/kaon/staking/kyvevaloper1myu8q0kdjm2hu2u5yhpna5uznm8xuqxm64y9dc'
    },
    {
        'id': 'zzzd',
        'name': 'okp4',
        'imgSrc': okp4,
        'link': 'https://okp4.explorers.guru/validator/okp4valoper15txyfswzc3tpzzv5xudyqkdw2dv5qmqhz8zmvx'
    },
    {
        'id': 'zzze',
        'name': 'lava',
        'imgSrc': lava,
        'link': 'https://lava.explorers.guru/validator/lava@valoper16l3u6mrjudttqcs3cuzasw6wyc4r4l3g47sjem'
    },
    {
        'id': 'zzzf',
        'name': 'andromeda',
        'imgSrc': andromeda,
        'link': 'https://andromeda.explorers.guru/validator/andrvaloper1kayjexylghad6jj2ax3uxl4wgxn7c2sk05q4tt'
    },
    {
        'id': 'zzzg',
        'name': 'babylon',
        'imgSrc': babylon,
        'link': 'https://babylon.explorers.guru/validator/bbnvaloper1gzdk4x8a888js2xr09utmtmpyc3tfyp8937x97'
    },
    // {
    //     'id': 'zzzh',
    //     'name': '',
    //     'imgSrc': ,
    // },
]