import kyve from '../assets/ico/Kyveico.jpeg'
import quicksilver from '../assets/ico/Quicksilverico.png'
import axelar from '../assets/ico/AxlIco.svg'
import stargaze from '../assets/ico/StargazeIco.svg'
import kujira from '../assets/ico/KujiraIco.png'
import kichain from '../assets/ico/KiIco.svg'
import kava from '../assets/ico/KavaIco.svg'
import teritori from '../assets/ico/TeritoriIco.svg'
import band from '../assets/ico/BandIco.svg'
import fetchai from '../assets/ico/FetchIco.svg'
import desmos from '../assets/ico/DesmosIco.svg'
import gbridge from '../assets/ico/GBridgeIco.svg'
import bitcanna from '../assets/ico/BcnaIco.svg'
import lum from '../assets/ico/LumIco.svg'
import provenance from '../assets/ico/ProvenanceIco.png'
import ixo from '../assets/ico/IxoIco.png'
import decentr from '../assets/ico/DecentrIco.svg'
import emoney from '../assets/ico/EmoneyIco.svg'
import rizon from '../assets/ico/RizonIco.svg'
import cosmos from '../assets/ico/CosmosIco.png'
import juno from '../assets/ico/JunoIco.png'
import osmo from '../assets/ico/OsmosisIco.png'

export const RelayerList = [
    {
        'id': 'hermesa',
        'name': 'Wallets Hermes 00',
        'wallets': [
            {
                'ico' : axelar,
                'addr': 'axelar16vmp7sz28pnvgz6f3zm6q93y39jsd33aszn9np',
                'link': 'https://www.mintscan.io/axelar/account/axelar16vmp7sz28pnvgz6f3zm6q93y39jsd33aszn9np'
            },
            {
                'ico' : cosmos,
                'addr': 'cosmos16vmp7sz28pnvgz6f3zm6q93y39jsd33a5v9dcq',
                'link': 'https://www.mintscan.io/cosmos/account/cosmos16vmp7sz28pnvgz6f3zm6q93y39jsd33a5v9dcq'
            },
            {
                'ico' : emoney,
                'addr': 'emoney1evdjzy3w9t2yu54w4dhc2cvrlc2fvnptla6yu9',
                'link': 'https://www.mintscan.io/emoney/account/emoney1evdjzy3w9t2yu54w4dhc2cvrlc2fvnptla6yu9'
            },
            {
                'ico' : juno,
                'addr': 'juno16vmp7sz28pnvgz6f3zm6q93y39jsd33az7xklu',
                'link': 'https://www.mintscan.io/juno/account/juno16vmp7sz28pnvgz6f3zm6q93y39jsd33az7xklu'
            },
            {
                'ico' : kujira,
                'addr': 'kujira1evdjzy3w9t2yu54w4dhc2cvrlc2fvnptpkzgxj',
                'link': 'https://www.mintscan.io/kujira/account/kujira1evdjzy3w9t2yu54w4dhc2cvrlc2fvnptpkzgxj'
            },
            {
                'ico' : osmo,
                'addr': 'osmo16vmp7sz28pnvgz6f3zm6q93y39jsd33auhkawj',
                'link': 'https://www.mintscan.io/osmosis/account/osmo16vmp7sz28pnvgz6f3zm6q93y39jsd33auhkawj'
            },
            {
                'ico' : stargaze,
                'addr': 'stars1evdjzy3w9t2yu54w4dhc2cvrlc2fvnptyzhdqf',
                'link': 'https://www.mintscan.io/stargaze/account/stars1evdjzy3w9t2yu54w4dhc2cvrlc2fvnptyzhdqf'
            },
            {
                'ico' : teritori,
                'addr': 'tori14uyfxlv00lj0qhcwt7vms2rsf7kxuld7k50mpv',
                'link': 'https://www.mintscan.io/teritori/account/tori14uyfxlv00lj0qhcwt7vms2rsf7kxuld7k50mpv'
            },
        ]
    },
    {
        'id': 'hermesb',
        'name': 'Wallets Hermes 01',
        'wallets': [
            {
                'ico' : axelar,
                'addr': 'axelar1evdjzy3w9t2yu54w4dhc2cvrlc2fvnpt5skcqe',
                'link': 'https://www.mintscan.io/axelar/account/axelar1evdjzy3w9t2yu54w4dhc2cvrlc2fvnpt5skcqe'
            },
            {
                'ico' : bitcanna,
                'addr': 'bcna1evdjzy3w9t2yu54w4dhc2cvrlc2fvnpt2ws3r2',
                'link': 'https://www.mintscan.io/bitcanna/account/bcna1evdjzy3w9t2yu54w4dhc2cvrlc2fvnpt2ws3r2'
            },
            {
                'ico' : cosmos,
                'addr': 'cosmos1evdjzy3w9t2yu54w4dhc2cvrlc2fvnpts7qstc',
                'link': 'https://www.mintscan.io/cosmos/account/cosmos1evdjzy3w9t2yu54w4dhc2cvrlc2fvnpts7qstc'
            },
            {
                'ico' : fetchai,
                'addr': 'fetch1evdjzy3w9t2yu54w4dhc2cvrlc2fvnptrrf5f0',
                'link': 'https://www.mintscan.io/fetchai/account/fetch1evdjzy3w9t2yu54w4dhc2cvrlc2fvnptrrf5f0'
            },
            {
                'ico' : gbridge,
                'addr': 'gravity1evdjzy3w9t2yu54w4dhc2cvrlc2fvnpt5wjgws',
                'link': 'https://www.mintscan.io/gravity-bridge/account/gravity1evdjzy3w9t2yu54w4dhc2cvrlc2fvnpt5wjgws'
            },
            {
                'ico' : juno,
                'addr': 'juno1evdjzy3w9t2yu54w4dhc2cvrlc2fvnptxvrtvy',
                'link': 'https://www.mintscan.io/juno/account/juno1evdjzy3w9t2yu54w4dhc2cvrlc2fvnptxvrtvy'
            },
            {
                'ico' : kava,
                'addr': 'kava1evdjzy3w9t2yu54w4dhc2cvrlc2fvnptvt5dal',
                'link': 'https://www.mintscan.io/kava/account/kava1evdjzy3w9t2yu54w4dhc2cvrlc2fvnptvt5dal'
            },
            {
                'ico' : kyve,
                'addr': 'kyve1evdjzy3w9t2yu54w4dhc2cvrlc2fvnpt8sdxqu',
                'link': 'https://www.mintscan.io/kyve/account/kyve1evdjzy3w9t2yu54w4dhc2cvrlc2fvnpt8sdxqu'
            },
            {
                'ico' : kichain,
                'addr': 'ki1evdjzy3w9t2yu54w4dhc2cvrlc2fvnptpn3l0v',
                'link': 'https://www.mintscan.io/ki-chain/account/ki1evdjzy3w9t2yu54w4dhc2cvrlc2fvnptpn3l0v'
            },
            {
                'ico' : osmo,
                'addr': 'osmo1evdjzy3w9t2yu54w4dhc2cvrlc2fvnptc9nqa2',
                'link': 'https://www.mintscan.io/osmosis/account/osmo1evdjzy3w9t2yu54w4dhc2cvrlc2fvnptc9nqa2'
            },
        ]
    },
    {
        'id': 'hermesc',
        'name': 'Wallets Hermes 02',
        'wallets': [
            {
                'ico' : cosmos,
                'addr': 'cosmos14uyfxlv00lj0qhcwt7vms2rsf7kxuld75qcj6u',
                'link': 'https://www.mintscan.io/cosmos/account/cosmos14uyfxlv00lj0qhcwt7vms2rsf7kxuld75qcj6u'
            },
            {
                'ico' : decentr,
                'addr': 'decentr1evdjzy3w9t2yu54w4dhc2cvrlc2fvnptmsxy2s',
                'link': 'https://explorer.teritori.com/decentr/account/decentr1evdjzy3w9t2yu54w4dhc2cvrlc2fvnptmsxy2s'
            },
            {
                'ico' : gbridge,
                'addr': 'gravity14uyfxlv00lj0qhcwt7vms2rsf7kxuld7ss22l5',
                'link': 'https://www.mintscan.io/gravity-bridge/account/gravity14uyfxlv00lj0qhcwt7vms2rsf7kxuld7ss22l5'
            },
            {
                'ico' : ixo,
                'addr': 'ixo1evdjzy3w9t2yu54w4dhc2cvrlc2fvnpt0t7z0t',
                'link': 'https://www.mintscan.io/ixo/account/ixo1evdjzy3w9t2yu54w4dhc2cvrlc2fvnpt0t7z0t'
            },
            {
                'ico' : juno,
                'addr': 'juno14uyfxlv00lj0qhcwt7vms2rsf7kxuld7zjmfaq',
                'link': 'https://www.mintscan.io/juno/account/juno14uyfxlv00lj0qhcwt7vms2rsf7kxuld7zjmfaq'
            },
            {
                'ico' : osmo,
                'addr': 'osmo14uyfxlv00lj0qhcwt7vms2rsf7kxuld7umtzvw',
                'link': 'https://www.mintscan.io/osmosis/account/osmo14uyfxlv00lj0qhcwt7vms2rsf7kxuld7umtzvw'
            },
            {
                'ico' : provenance,
                'addr': 'pb16vmp7sz28pnvgz6f3zm6q93y39jsd33aazwg4u',
                'link': 'https://www.mintscan.io/provenance/account/pb16vmp7sz28pnvgz6f3zm6q93y39jsd33aazwg4u'
            },
            {
                'ico' : quicksilver,
                'addr': 'quick14uyfxlv00lj0qhcwt7vms2rsf7kxuld7lygqrw',
                'link': 'https://www.mintscan.io/quicksilver/account/quick14uyfxlv00lj0qhcwt7vms2rsf7kxuld7lygqrw'
            },
            {
                'ico' : stargaze,
                'addr': 'stars14uyfxlv00lj0qhcwt7vms2rsf7kxuld7qu003d',
                'link': 'https://www.mintscan.io/stargaze/account/stars14uyfxlv00lj0qhcwt7vms2rsf7kxuld7qu003d'
            },
        ]
    },
    {
        'id': 'hermesd',
        'name': 'Wallets Hermes 03',
        'wallets': [
            {
                'ico' : band,
                'addr': 'band1evdjzy3w9t2yu54w4dhc2cvrlc2fvnptf3qjns',
                'link': 'https://www.mintscan.io/band/account/band1evdjzy3w9t2yu54w4dhc2cvrlc2fvnptf3qjns'
            },
            {
                'ico' : cosmos,
                'addr': 'cosmos1tdl8wlqx0w8laeqytxx9rphfcu7cm2cygxyfyh',
                'link': 'https://www.mintscan.io/cosmos/account/cosmos1tdl8wlqx0w8laeqytxx9rphfcu7cm2cygxyfyh'
            },
            {
                'ico' : desmos,
                'addr': 'desmos1evdjzy3w9t2yu54w4dhc2cvrlc2fvnptyxdquq',
                'link': 'https://www.mintscan.io/desmos/account/desmos1evdjzy3w9t2yu54w4dhc2cvrlc2fvnptyxdquq'
            },
            {
                'ico' : gbridge,
                'addr': 'gravity16vmp7sz28pnvgz6f3zm6q93y39jsd33asuh4ag',
                'link': 'https://www.mintscan.io/gravity-bridge/account/gravity16vmp7sz28pnvgz6f3zm6q93y39jsd33asuh4ag'
            },
            {
                'ico' : kava,
                'addr': 'kava16vmp7sz28pnvgz6f3zm6q93y39jsd33age3sw8',
                'link': 'https://www.mintscan.io/kava/account/kava16vmp7sz28pnvgz6f3zm6q93y39jsd33age3sw8'
            },
            {
                'ico' : kujira,
                'addr': 'kujira14uyfxlv00lj0qhcwt7vms2rsf7kxuld79g62hk',
                'link': 'https://www.mintscan.io/kujira/account/kujira14uyfxlv00lj0qhcwt7vms2rsf7kxuld79g62hk'
            },
            {
                'ico' : lum,
                'addr': 'lum1evdjzy3w9t2yu54w4dhc2cvrlc2fvnpt95ae7v',
                'link': 'https://www.mintscan.io/lum/account/lum1evdjzy3w9t2yu54w4dhc2cvrlc2fvnpt95ae7v'
            },
            {
                'ico' : osmo,
                'addr': 'osmo1tdl8wlqx0w8laeqytxx9rphfcu7cm2cyqahej9',
                'link': 'https://www.mintscan.io/osmosis/account/osmo1tdl8wlqx0w8laeqytxx9rphfcu7cm2cyqahej9'
            },
            {
                'ico' : rizon,
                'addr': 'rizon1evdjzy3w9t2yu54w4dhc2cvrlc2fvnptnrup8q',
                'link': 'https://www.mintscan.io/rizon/account/rizon1evdjzy3w9t2yu54w4dhc2cvrlc2fvnptnrup8q'
            },
            {
                'ico' : teritori,
                'addr': 'tori1evdjzy3w9t2yu54w4dhc2cvrlc2fvnptj2hesg',
                'link': 'https://www.mintscan.io/teritori/account/tori1evdjzy3w9t2yu54w4dhc2cvrlc2fvnptj2hesg'
            },
        ]
    },
]