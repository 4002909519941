import api from '../assets/ico/ApiIco.jpg'
import rpc from '../assets/ico/RpcIco.jpg'
import statesync from '../assets/ico/StatesyncIco.jpg'
import snapshot from '../assets/ico/SnapshotIco.jpg'

import gitopia from '../assets/ico/GitopiaIco.png'
import kyve from '../assets/ico/Kyveico.jpeg'
import quicksilver from '../assets/ico/Quicksilverico.png'
import axelar from '../assets/ico/AxlIco.svg'
import stargaze from '../assets/ico/StargazeIco.svg'
import kujira from '../assets/ico/KujiraIco.png'
import kichain from '../assets/ico/KiIco.svg'
import kava from '../assets/ico/KavaIco.svg'
import teritori from '../assets/ico/TeritoriIco.svg'
import passage from '../assets/ico/PasgIcon.png'
import band from '../assets/ico/BandIco.svg'
import fetchai from '../assets/ico/FetchIco.svg'
import desmos from '../assets/ico/DesmosIco.svg'
import gbridge from '../assets/ico/GBridgeIco.svg'
import bitcanna from '../assets/ico/BcnaIco.svg'
import lum from '../assets/ico/LumIco.svg'
import provenance from '../assets/ico/ProvenanceIco.png'
import ixo from '../assets/ico/IxoIco.png'
import decentr from '../assets/ico/DecentrIco.svg'
import emoney from '../assets/ico/EmoneyIco.svg'
import rizon from '../assets/ico/RizonIco.svg'

export const PublicServicesList = [
    {
        'id': 'aaa',
        'ico': api,
        'category': 'API',
        'all': [
            {
                'ico': axelar,
                'name': 'Axelar',
                'link': 'https://axelar-api.ibs.team/'
            },
            {
                'ico': bitcanna,
                'name': 'Bitcanna',
                'link': 'https://bcna-api.ibs.team/'
            },
            {
                'ico': band,
                'name': 'Band',
                'link': 'https://band-api.ibs.team/'
            },
            {
                'ico': decentr,
                'name': 'Decentr',
                'link': 'https://decentr-api.ibs.team/'
            },
            {
                'ico': desmos,
                'name': 'Desmos',
                'link': 'https://desmos-api.ibs.team/swagger/'
            },
            {
                'ico': emoney,
                'name': 'E-money',
                'link': 'https://e-money-api.ibs.team/swagger/'
            },
            {
                'ico': fetchai,
                'name': 'Fetch.ai',
                'link': 'https://fetch-api.ibs.team/swagger/'
            },
            {
                'ico': gitopia,
                'name': 'Gitopia',
                'link': 'https://gitopia-api.ibs.team/swagger/'
            },
            {
                'ico': gbridge,
                'name': 'Gravity',
                'link': 'https://gravity-api.ibs.team/swagger/'
            },
            {
                'ico': ixo,
                'name': 'Ixo',
                'link': 'https://ixo-api.ibs.team/swagger/'
            },
            {
                'ico': kava,
                'name': 'Kava',
                'link': 'https://kava-api.ibs.team/swagger/'
            },
            {
                'ico': kichain,
                'name': 'Ki',
                'link': 'https://ki-api.ibs.team/swagger/'
            },
            {
                'ico': kujira,
                'name': 'Kujira',
                'link': 'https://kujira-api.ibs.team/'
            },
            {
                'ico': kyve,
                'name': 'Kyve',
                'link': 'https://kyve-api.ibs.team/'
            },
            {
                'ico': lum,
                'name': 'Lum',
                'link': 'https://lum-api.ibs.team/'
            },
            {
                'ico': passage,
                'name': 'Passage',
                'link': 'https://passage-api.ibs.team/swagger/'
            },
            {
                'ico': provenance,
                'name': 'Provenance',
                'link': 'https://provenance-api.ibs.team/swagger/'
            },
            {
                'ico': quicksilver,
                'name': 'Quicksilver',
                'link': 'https://quicksilver-api.ibs.team/swagger/'
            },
            {
                'ico': rizon,
                'name': 'Rizon',
                'link': 'https://rizon-api.ibs.team/swagger/'
            },
            {
                'ico': stargaze,
                'name': 'Stargaze',
                'link': 'https://stargaze-api.ibs.team/'
            },
            {
                'ico': teritori,
                'name': 'Teritori',
                'link': 'https://teritori-api.ibs.team/swagger/'
            }
        ]
    },
    {
        'id': 'aab',
        'ico': rpc,
        'category': 'RPC',
        'all': [
            {
                'ico': axelar,
                'name': 'Axelar',
                'link': 'https://axelar-rpc.ibs.team/'
            },
            {
                'ico': bitcanna,
                'name': 'Bitcanna',
                'link': 'https://bcna-rpc.ibs.team/'
            },
            {
                'ico': band,
                'name': 'Band',
                'link': 'https://band-rpc.ibs.team/'
            },
            {
                'ico': decentr,
                'name': 'Decentr',
                'link': 'https://decentr-rpc.ibs.team/'
            },
            {
                'ico': desmos,
                'name': 'Desmos',
                'link': 'https://desmos-rpc.ibs.team/'
            },
            {
                'ico': emoney,
                'name': 'E-money',
                'link': 'https://e-money-rpc.ibs.team/'
            },
            {
                'ico': fetchai,
                'name': 'Fetch.ai',
                'link': 'https://fetch-rpc.ibs.team/'
            },
            {
                'ico': gitopia,
                'name': 'Gitopia',
                'link': 'https://gitopia-rpc.ibs.team/'
            },
            {
                'ico': gbridge,
                'name': 'Gravity',
                'link': 'https://gravity-rpc.ibs.team/'
            },
            {
                'ico': ixo,
                'name': 'Ixo',
                'link': 'https://ixo-rpc.ibs.team/'
            },
            {
                'ico': kava,
                'name': 'Kava',
                'link': 'https://kava-rpc.ibs.team/'
            },
            {
                'ico': kichain,
                'name': 'Ki',
                'link': 'https://ki-rpc.ibs.team/'
            },
            {
                'ico': kujira,
                'name': 'Kujira',
                'link': 'https://kujira-rpc.ibs.team/'
            },
            {
                'ico': kyve,
                'name': 'Kyve',
                'link': 'https://kyve-rpc.ibs.team/'
            },
            {
                'ico': lum,
                'name': 'Lum',
                'link': 'https://lum-rpc.ibs.team/'
            },
            {
                'ico': passage,
                'name': 'Passage',
                'link': 'https://passage-rpc.ibs.team/'
            },
            {
                'ico': provenance,
                'name': 'Provenance',
                'link': 'https://provenance-rpc.ibs.team/'
            },
            {
                'ico': quicksilver,
                'name': 'Quicksilver',
                'link': 'https://quicksilver-rpc.ibs.team/'
            },
            {
                'ico': rizon,
                'name': 'Rizon',
                'link': 'https://rizon-rpc.ibs.team/'
            },
            {
                'ico': stargaze,
                'name': 'Stargaze',
                'link': 'https://stargaze-rpc.ibs.team/'
            },
            {
                'ico': teritori,
                'name': 'Teritori',
                'link': 'https://teritori-rpc.ibs.team/'
            }
        ]
    },
    {
        'id': 'aac',
        'ico': statesync,
        'category': 'STATESYNC',
        'all': [
            {
                'ico': bitcanna,
                'name': 'Bitcanna',
                'link': 'https://github.com/Inter-Blockchain-Service/Cosmos-StateSync/tree/main/Bitcanna'
            },
            {
                'ico': decentr,
                'name': 'Decentr',
                'link': 'https://github.com/Inter-Blockchain-Service/Cosmos-StateSync/tree/main/Decentr'
            },
            {
                'ico': desmos,
                'name': 'Desmos',
                'link': 'https://github.com/Inter-Blockchain-Service/Cosmos-StateSync/tree/main/Desmos'
            },
            {
                'ico': fetchai,
                'name': 'Fetch.ai',
                'link': 'https://github.com/Inter-Blockchain-Service/Cosmos-StateSync/tree/main/Fetch'
            },
            {
                'ico': gitopia,
                'name': 'Gitopia',
                'link': 'https://github.com/Inter-Blockchain-Service/Cosmos-StateSync/tree/main/Gitopia'
            },
            {
                'ico': gbridge,
                'name': 'Gravity',
                'link': 'https://github.com/Inter-Blockchain-Service/Cosmos-StateSync/tree/main/Gravity_Bridge'
            },
            {
                'ico': ixo,
                'name': 'Ixo',
                'link': 'https://github.com/Inter-Blockchain-Service/Cosmos-StateSync/tree/main/Ixo'
            },
            {
                'ico': kichain,
                'name': 'Ki',
                'link': 'https://github.com/Inter-Blockchain-Service/Cosmos-StateSync/tree/main/Ki'
            },
            {
                'ico': kujira,
                'name': 'Kujira',
                'link': 'https://github.com/Inter-Blockchain-Service/Cosmos-StateSync/tree/main/Kujira'
            },
            {
                'ico': kyve,
                'name': 'Kyve',
                'link': 'https://github.com/Inter-Blockchain-Service/Cosmos-StateSync/tree/main/Kyve'
            },
            {
                'ico': passage,
                'name': 'Passage',
                'link': 'https://github.com/Inter-Blockchain-Service/Cosmos-StateSync/tree/main/Passage'
            },
            {
                'ico': quicksilver,
                'name': 'Quicksilver',
                'link': 'https://github.com/Inter-Blockchain-Service/Cosmos-StateSync/tree/main/Quicksilver'
            },
            {
                'ico': rizon,
                'name': 'Rizon',
                'link': 'https://github.com/Inter-Blockchain-Service/Cosmos-StateSync/tree/main/Rizon'
            },
            {
                'ico': stargaze,
                'name': 'Stargaze',
                'link': 'https://github.com/Inter-Blockchain-Service/Cosmos-StateSync/tree/main/Stargaze'
            },
            {
                'ico': teritori,
                'name': 'Teritori',
                'link': 'https://github.com/Inter-Blockchain-Service/Cosmos-StateSync/tree/main/Teritori'
            }
        ]
    },
    {
        'id': 'aad',
        'ico': snapshot,
        'category': 'SNAPSHOT',
        'all': [
            {
                'ico': bitcanna,
                'name': 'Bitcanna',
                'link': 'https://snapshot.ibs.team/Bitcanna/'
            },
            {
                'ico': decentr,
                'name': 'Decentr',
                'link': 'https://snapshot.ibs.team/Decentr/'
            },
            {
                'ico': desmos,
                'name': 'Desmos',
                'link': 'https://snapshot.ibs.team/Desmos/'
            },
            {
                'ico': fetchai,
                'name': 'Fetch.ai',
                'link': 'https://snapshot.ibs.team/Fetch.ai/'
            },
            {
                'ico': gitopia,
                'name': 'Gitopia',
                'link': 'https://snapshot.ibs.team/Gitopia/'
            },
            {
                'ico': gbridge,
                'name': 'Gravity',
                'link': 'https://snapshot.ibs.team/Gravity-Bridge/'
            },
            {
                'ico': ixo,
                'name': 'Ixo',
                'link': 'https://snapshot.ibs.team/Ixo/'
            },
            {
                'ico': kichain,
                'name': 'Ki',
                'link': 'https://snapshot.ibs.team/Ki/'
            },
            {
                'ico': kyve,
                'name': 'Kyve',
                'link': 'https://snapshot.ibs.team/Kyve/'
            },
            {
                'ico': passage,
                'name': 'Passage',
                'link': 'https://snapshot.ibs.team/Passage/'
            },
            {
                'ico': quicksilver,
                'name': 'Quicksilver',
                'link': 'https://snapshot.ibs.team/Quicksilver'
            },
            {
                'ico': rizon,
                'name': 'Rizon',
                'link': 'https://snapshot.ibs.team/Rizon/'
            },
            {
                'ico': stargaze,
                'name': 'Stargaze',
                'link': 'https://snapshot.ibs.team/Stargaze'
            },
            {
                'ico': teritori,
                'name': 'Teritori',
                'link': 'https://snapshot.ibs.team/Teritori'
            }
        ]
    },
    // {
    //     'id': 'aad',
    //     'ico': '',
    //     'category': '',
    //     'all': [
    //         {
    //             'ico': ,
    //             'name': '',
    //             'link': ''
    //         },
    //     ]
    // },
]