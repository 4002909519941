import { useEffect } from 'react';
import ibs from '../../assets/IBS_HORIZ.png'
import './navbar.sass'

const Navbar = () => {
    useEffect(() => {
        //DEBUT FONCTION DETECTION ANCRE NOMME----------------------------------------------------------------------------------------
        // Sélectionnez toutes les ancres de navigation
        const navAnchors = document.querySelectorAll('.header__navbar__list__link')
        // Fonction pour déterminer quelle ancre est visible
        function detectVisibleAnchor() {
            const windowTop = window.scrollY + 5
            for (const anchor of navAnchors) {
                const targetId = anchor.getAttribute('href').substring(1) // Récupére l'ID de l'ancre
                const targetElement = document.getElementById(targetId)
                if (targetElement) {
                    const elementTop = targetElement.offsetTop
                    const elementHeight = targetElement.offsetHeight
                    // Vérifiez si l'ancre est visible dans la fenêtre
                    if (windowTop >= elementTop && windowTop < elementTop + elementHeight) {
                        // Ajoutez une classe active à l'ancre visible
                        anchor.classList.add('active')
                    } else {
                        // Supprimez la classe active des ancres non visibles
                        anchor.classList.remove('active')
                    }
                }
            }
        }
        detectVisibleAnchor()
        window.addEventListener('scroll', () => {
            detectVisibleAnchor()
        })
        //FIN FONCTION DETECTION ANCRE NOMME----------------------------------------------------------------------------------------
        //DEBUT FONCTION DEFILEMENT----------------------------------------------------------------------------------------
        // Fonction pour le défilement doux vers l'ancre
        function scrollToAnchor(targetId) {
            const targetElement = document.getElementById(targetId)
            if (targetElement) {
                const offset = targetElement.getBoundingClientRect().top
                const startPosition = window.pageYOffset
                const startTime = 'now' in window.performance ? performance.now() : new Date().getTime()
                function scroll() {
                    const currentTime = 'now' in window.performance ? performance.now() : new Date().getTime()
                    const elapsed = currentTime - startTime
                    const progress = Math.min(elapsed / 1000, 1)
                    window.scrollTo(0, startPosition + offset * easeInOutCubic(progress))
                    if (progress < 1) {
                        requestAnimationFrame(scroll)
                    }
                }
                // Démarrez l'animation de défilement doux
                requestAnimationFrame(scroll)
            }
        }
        // Fonction d'interpolation pour l'effet de défilement doux (ease-in-out cubic)
        function easeInOutCubic(t) {
            return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1
        }
        // Gérez le clic sur les liens de navigation pour le défilement doux
        function handleNavClick(e) {
            const targetId = e.currentTarget.getAttribute('href').substring(1) // Récupérez l'ID de la cible
            scrollToAnchor(targetId)
        }
        // Attachez un gestionnaire d'événements de clic à tous les liens de navigation
            const navLinks = document.querySelectorAll('.header__navbar__list__link')
            navLinks.forEach((link) => {
                link.addEventListener('click', handleNavClick)
            })
    //FIN FONCTION DEFILEMENT----------------------------------------------------------------------------------------
    }, [])
    //DEBUT FONCTION SPAWN LOGO----------------------------------------------------------------------------------------
    function spawnLogo() {
        const logo = document.getElementById('logo')
        window.addEventListener('scroll', function(e){
            const windowTop = window.scrollY
            if (windowTop >= 300){
                logo.style.opacity = '1'
            } else if (windowTop <= 299) {
                logo.style.transition = 'opacity 1000ms ease-in'
                logo.style.opacity = '0'
            }
        })
    }
    // Appelez les fonction lors du chargement de la page et lors du défilement
    window.addEventListener('scroll', () => {
        spawnLogo()
    })
    //FIN FONCTION SPAWN LOGO----------------------------------------------------------------------------------------
    //DEBUT FONCTION MENU MOBILE----------------------------------------------------------------------------------------
    let test = false
    const toggleMenu = (e) => {
        // const button = document.querySelector('.header__navbar__btn')
        const list = document.querySelector('.header__navbar__list')
        if(!test){
            test = true
            list.style.right = '0%'
        } else {
            test = false
            list.style.right = '-100%'
        }
    }
    const handleClickOutside = (e) => {
        const button = e.target.classList.contains('header__navbar__btn__ico')
        const list = document.querySelector('.header__navbar__list')
        if(!button){
            test = false
            list.style.right = '-100%'
        }
    }
    document.addEventListener('click', (e) => {
        handleClickOutside(e)
    })
    //FIN FONCTION MENU MOBILE----------------------------------------------------------------------------------------
    return (
        <header className='header'>
            <nav className='header__navbar'>
                <img className='header__navbar__logo' id='logo' src={ibs} alt="logo Inter Blockchain Services" />
                <button className='header__navbar__btn' onClick={toggleMenu} aria-label='bouton menu'>
                    <i className='fas fa-bars fa-xl header__navbar__btn__ico' ></i>
                </button>
                <div className='header__navbar__list'>
                    <a className='header__navbar__list__link' href='#about'>accueil</a>
                    <a className='header__navbar__list__link' href='#mainnet'>mainnet</a>
                    <a className='header__navbar__list__link' href='#testnet'>testnet</a>
                    <a className='header__navbar__list__link' href='#services'>services</a>
                    <a className='header__navbar__list__link' href='#relayer'>relayer</a>
                </div>
            </nav>
        </header>
    )
}

export default Navbar;