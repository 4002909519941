import gitopia from '../assets/ico/GitopiaIco.png'
import kyve from '../assets/ico/Kyveico.jpeg'
import quicksilver from '../assets/ico/Quicksilverico.png'
import axelar from '../assets/ico/AxlIco.svg'
import stargaze from '../assets/ico/StargazeIco.svg'
import kujira from '../assets/ico/KujiraIco.png'
import kichain from '../assets/ico/KiIco.svg'
import kava from '../assets/ico/KavaIco.svg'
import teritori from '../assets/ico/TeritoriIco.svg'
import passage from '../assets/ico/PasgIcon.png'
import band from '../assets/ico/BandIco.svg'
import fetchai from '../assets/ico/FetchIco.svg'
import desmos from '../assets/ico/DesmosIco.svg'
import gbridge from '../assets/ico/GBridgeIco.svg'
import bitcanna from '../assets/ico/BcnaIco.svg'
import lum from '../assets/ico/LumIco.svg'
import provenance from '../assets/ico/ProvenanceIco.png'
import ixo from '../assets/ico/IxoIco.png'
import decentr from '../assets/ico/DecentrIco.svg'
import emoney from '../assets/ico/EmoneyIco.svg'
import rizon from '../assets/ico/RizonIco.svg'
const million = 1000000
const milliard = 1000000000
const beaucoup = 1000000000000000000


export const mainnetList = [
    {
        'id': 'aaaa',
        'ico' : gitopia,
        'name': 'gitopia',
        'token': 'LORE',
        'stake': 'https://ping.pub/gitopia/staking/gitopiavaloper196g54yghsu6q6mmk2tm6jx3nd3qfsxgj08p3m7',
        'explore': 'https://ping.pub/gitopia/staking',
        'addr': '',
        'apr': 14.25,
        'calc': million
    },
    {
        'id': 'aaab',
        'ico' : kyve,
        'name': 'kyve',
        'token': 'KYVE',
        'stake': 'https://restake.app/kyve/kyvevaloper1awxv4js9aumf6kp2803vxx3lgckt5cxwanutal',
        'explore': 'https://www.mintscan.io/kyve/validators/kyvevaloper1awxv4js9aumf6kp2803vxx3lgckt5cxwanutal',
        'addr': '',
        'apr': 31.69,
        'calc': million
    },
    {
        'id': 'aaac',
        'ico' : quicksilver,
        'name': 'quicksilver',
        'token': 'QCK',
        'stake': 'https://wallet.keplr.app/chains/quicksilver?modal=validator&chain=quicksilver-1&validator_address=quickvaloper18neym00lprkc6wt0ycpucqcxcdsuktlqw79muk',
        'explore': 'https://www.mintscan.io/quicksilver/validators/quickvaloper18neym00lprkc6wt0ycpucqcxcdsuktlqw79muk',
        'addr': 'https://quicksilver-api.ibs.team/cosmos/staking/v1beta1/validators/quickvaloper18neym00lprkc6wt0ycpucqcxcdsuktlqw79muk',
        'apr': 47.07,
        'calc': million
    },
    {
        'id': 'aaad',
        'ico' : axelar,
        'name': 'axelar',
        'token': 'AXL',
        'stake': 'https://wallet.keplr.app/chains/axelar?modal=validator&chain=axelar-dojo-1&validator_address=axelarvaloper126yfkvn7lx280ccg2lnxty0n2ldzz6xnve3smx',
        'explore': 'https://www.mintscan.io/axelar/validators/axelarvaloper126yfkvn7lx280ccg2lnxty0n2ldzz6xnve3smx',
        'addr': '',
        'apr': 16.2,
        'calc': million
    },
    {
        'id': 'aaae',
        'ico' : stargaze,
        'name': 'stargaze',
        'token': 'STARS',
        'stake': 'https://wallet.keplr.app/#/stargaze/stake?modal=detail&chainId=stargaze-1&validator=starsvaloper1yskcx5zfkr3nacn6f5zd08yw72zwm4s06x8tpz',
        'explore': 'https://www.mintscan.io/stargaze/validators/starsvaloper1yskcx5zfkr3nacn6f5zd08yw72zwm4s06x8tpz',
        'addr': 'https://stargaze-api.ibs.team/cosmos/staking/v1beta1/validators/starsvaloper1yskcx5zfkr3nacn6f5zd08yw72zwm4s06x8tpz',
        'apr': 23.22,
        'calc': million
    },
    {
        'id': 'aaaf',
        'ico' : kujira,
        'name': 'kujira',
        'token': 'KUJI',
        'stake': 'https://blue.kujira.app/stake/kujiravaloper1vmmlphwck04khtwj69ghuewux5hrmvwwc43j77',
        'explore': 'https://blue.kujira.app/stake?view=table',
        'addr': 'https://kujira-api.ibs.team/cosmos/staking/v1beta1/validators/kujiravaloper1vmmlphwck04khtwj69ghuewux5hrmvwwc43j77',
        'apr': 0.6,
        'calc': million
    },
    {
        'id': 'aaag',
        'ico' : kichain,
        'name': 'kichain',
        'token': 'XKI',
        'stake': 'https://ping.pub/kichain/staking/kivaloper1aupvcledkeqewaz397l9v6wrjv472j80kcwjde',
        'explore': 'https://www.mintscan.io/ki-chain/validators/kivaloper1aupvcledkeqewaz397l9v6wrjv472j80kcwjde',
        'addr': 'https://ki-api.ibs.team/cosmos/staking/v1beta1/validators/kivaloper1aupvcledkeqewaz397l9v6wrjv472j80kcwjde',
        'apr': 8.31,
        'calc': million
    },
    {
        'id': 'aaah',
        'ico' : kava,
        'name': 'kava',
        'token': 'KAVA',
        'stake': 'https://wallet.keplr.app/#/kava/stake?modal=detail&chainId=kava-9&validator=kavavaloper1w2f95dx7p35ye6mzaanxkpwadye0vr7375avdk',
        'explore': 'https://www.mintscan.io/kava/validators/kavavaloper1w2f95dx7p35ye6mzaanxkpwadye0vr7375avdk',
        'addr': '',
        'apr': 19.09,
        'calc': million
    },
    {
        'id': 'aaai',
        'ico' : teritori,
        'name': 'teritori',
        'token': 'TORI',
        'stake': 'https://explorer.teritori.com/teritori/staking/torivaloper15n624eajd04jjhnlvza2fvft3lmf69aegvyml5',
        'explore': 'https://explorer.teritori.com/teritori/staking',
        'addr': 'https://teritori-api.ibs.team/cosmos/staking/v1beta1/validators/torivaloper15n624eajd04jjhnlvza2fvft3lmf69aegvyml5',
        'apr': 106.6,
        'calc': million
    },
    {
        'id': 'aaaj',
        'ico' : passage,
        'name': 'passage',
        'token': 'PASG',
        'stake': 'https://ping.pub/passage/staking/pasgvaloper125djpa8sfz2vk4qjwr9nut9utgv8gvqfnq2zqv',
        'explore': 'https://www.mintscan.io/passage/validators/pasgvaloper125djpa8sfz2vk4qjwr9nut9utgv8gvqfnq2zqv',
        'addr': 'https://passage-api.ibs.team/cosmos/staking/v1beta1/validators/pasgvaloper125djpa8sfz2vk4qjwr9nut9utgv8gvqfnq2zqv',
        'apr': 37.14,
        'calc': million
    },
    {
        'id': 'aaak',
        'ico' : band,
        'name': 'band',
        'token': 'BAND',
        'stake': 'https://ping.pub/band-protocol/staking/bandvaloper12v3458kg298ckge36lsqafmmggj3pywj062p2q',
        'explore': 'https://www.mintscan.io/band/validators/bandvaloper12v3458kg298ckge36lsqafmmggj3pywj062p2q',
        'addr': '',
        'apr': 10.84,
        'calc': million
    },
    {
        'id': 'aaal',
        'ico' : fetchai,
        'name': 'fetch.ai',
        'token': 'FET',
        'stake': 'https://wallet.cosmostation.io/fetch-ai/delegate',
        'explore': 'https://www.mintscan.io/fetchai/validators/fetchvaloper1d2m7xut58hd9lh8ccvtpfp49yeuwyrd4a3qgx4',
        'addr': 'https://fetch-api.ibs.team/cosmos/staking/v1beta1/validators/fetchvaloper1d2m7xut58hd9lh8ccvtpfp49yeuwyrd4a3qgx4',
        'apr': 8.77,
        'calc': beaucoup
    },
    {
        'id': 'aaam',
        'ico' : desmos,
        'name': 'desmos',
        'token': 'DSM',
        'stake': 'https://ping.pub/desmos/staking/desmosvaloper1jrld5g998gqm4yx26l6cvhxz7y5adgxqzfdpes',
        'explore': 'https://www.mintscan.io/desmos/validators/desmosvaloper1jrld5g998gqm4yx26l6cvhxz7y5adgxqzfdpes',
        'addr': 'https://desmos-api.ibs.team/cosmos/staking/v1beta1/validators/desmosvaloper1jrld5g998gqm4yx26l6cvhxz7y5adgxqzfdpes',
        'apr': 22.59,
        'calc': million
    },
    {
        'id': 'aaan',
        'ico' : gbridge,
        'name': 'g-bridge',
        'token': 'GRAVITON',
        'stake': 'https://ping.pub/gravity-bridge/staking/gravityvaloper1uuzcgwd0yerq6yfk2fewfknl0rf6aqgdd0p3a3',
        'explore': 'https://www.mintscan.io/gravity-bridge/validators/gravityvaloper1uuzcgwd0yerq6yfk2fewfknl0rf6aqgdd0p3a3',
        'addr': 'https://gravity-api.ibs.team/cosmos/staking/v1beta1/validators/gravityvaloper1uuzcgwd0yerq6yfk2fewfknl0rf6aqgdd0p3a3',
        'apr': 15.01,
        'calc': million
    },
    {
        'id': 'aaao',
        'ico' : bitcanna,
        'name': 'bitcanna',
        'token': 'BCNA',
        'stake': 'https://ping.pub/bitcanna/staking/bcnavaloper1hmjwnv96eg30mc7vg2wes7eu2esnk9ujdwaccu',
        'explore': 'https://www.mintscan.io/bitcanna/validators/bcnavaloper1hmjwnv96eg30mc7vg2wes7eu2esnk9ujdwaccu',
        'addr': 'https://bitcanna-api.lavenderfive.com/cosmos/staking/v1beta1/validators/bcnavaloper1hmjwnv96eg30mc7vg2wes7eu2esnk9ujdwaccu',
        'apr': 35.43,
        'calc': million
    },
    {
        'id': 'aaap',
        'ico' : lum,
        'name': 'lum',
        'token': 'LUM',
        'stake': 'https://ping.pub/LUM%20Network/staking/lumvaloper1mjxsv6j9e2srh36ncsuj5yhlxa0vu97a95mvw6',
        'explore': 'https://www.mintscan.io/lum/validators/lumvaloper1mjxsv6j9e2srh36ncsuj5yhlxa0vu97a95mvw6',
        'addr': '',
        'apr': 44.49,
        'calc': million
    },
    {
        'id': 'aaaq',
        'ico' : provenance,
        'name': 'provenance',
        'token': 'HASH',
        'stake': 'https://ping.pub/provenance/staking/pbvaloper14ypfvhc77q09pz0j8wt9xkm2css6lqdrejwmss',
        'explore': 'https://www.mintscan.io/provenance/validators/pbvaloper14ypfvhc77q09pz0j8wt9xkm2css6lqdrejwmss',
        'addr': 'https://provenance-api.ibs.team/cosmos/staking/v1beta1/validators/pbvaloper14ypfvhc77q09pz0j8wt9xkm2css6lqdrejwmss',
        'apr': null,
        'calc': milliard
    },
    {
        'id': 'aaar',
        'ico' : ixo,
        'name': 'ixo',
        'token': 'IXO',
        'stake': 'https://wallet.keplr.app/#/impacthub/stake?modal=detail&validator=ixovaloper1099enmr6x2kj0gk5rd8r6vaupj9yr6k6ej6zj8',
        'explore': 'https://www.mintscan.io/ixo/validators/ixovaloper1099enmr6x2kj0gk5rd8r6vaupj9yr6k6ej6zj8',
        'addr': 'https://ixo-api.ibs.team/cosmos/staking/v1beta1/validators/ixovaloper1099enmr6x2kj0gk5rd8r6vaupj9yr6k6ej6zj8',
        'apr': 39.19,
        'calc': million
    },
    {
        'id': 'aaas',
        'ico' : decentr,
        'name': 'decentr',
        'token': 'DEC',
        'stake': 'https://ping.pub/fetchhub/staking/fetchvaloper1d2m7xut58hd9lh8ccvtpfp49yeuwyrd4a3qgx4',
        'explore': 'https://explorer.decentr.net/validators/decentrvaloper1uvv5cm4e4xswlt5ca0q7sussd7gxtd2ys4spa6?networkId=mainnet',
        'addr': 'https://decentr-api.ibs.team/cosmos/staking/v1beta1/validators/decentrvaloper1uvv5cm4e4xswlt5ca0q7sussd7gxtd2ys4spa6',
        'apr': 20,
        'calc': million
    },
    {
        'id': 'aaat',
        'ico' : emoney,
        'name': 'e-money',
        'token': 'NGM',
        'stake': 'https://wallet.keplr.app/#/emoney/stake?modal=detail&chainId=emoney-3&validator=emoneyvaloper1n065t8znwxqj3t354janr57tlwvezygc6aqk32',
        'explore': 'https://www.mintscan.io/emoney/validators/emoneyvaloper1n065t8znwxqj3t354janr57tlwvezygc6aqk32',
        'addr': 'https://e-money-api.ibs.team/cosmos/staking/v1beta1/validators/emoneyvaloper1n065t8znwxqj3t354janr57tlwvezygc6aqk32',
        'apr': 23.42,
        'calc': million
    },
    {
        'id': 'aaau',
        'ico' : rizon,
        'name': 'rizon',
        'token': 'ATOLO',
        'stake': 'https://ping.pub/rizon/staking/rizonvaloper14j63wdmclzckdr9qlgqcrfc4yrh2r2zg8vufr5',
        'explore': 'https://www.mintscan.io/rizon/validators/rizonvaloper14j63wdmclzckdr9qlgqcrfc4yrh2r2zg8vufr5',
        'addr': 'https://rizon-api.ibs.team/cosmos/staking/v1beta1/validators/rizonvaloper14j63wdmclzckdr9qlgqcrfc4yrh2r2zg8vufr5',
        'apr': 23.15,
        'calc': million
    },
    // {
    //     'id': 'aaav',
    //     'ico' : {},
    //     'name': '',
    //     'stake': '',
    //     'explore': '',
    //     'addr': '',
    //     'calc': 
    // },
]