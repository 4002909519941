import { testnetList } from '../../data/testnet'
import './testnet.sass'

const Testnet = () => {
    return (
        <section className='testnet' id='testnet'>
            <h2 className='testnet__title'>testnet</h2>
            <div className='testnet__container'>
                {testnetList.map((e) => (
                    <div className='testnet__container__card' key={e.id}>
                        <a href={e.link} target='_blank' rel="noopener noreferrer">
                            <img className='testnet__container__card__img' src={e.imgSrc} alt={e.name} />
                        </a>
                    </div>
                ))}
            </div>
        </section>
    )
}
export default Testnet