import Navbar from "../../components/navbar/navbar"
import About from "../../components/about/about"
import Mainnet from "../../components/mainnet/mainnet"
import Testnet from "../../components/testnet/testnet"
import PublicServices from "../../components/publicServices/publicServices"
import Relayer from "../../components/relayer/relayer"
import Footer from "../../components/footer/footer"

const Home = () => {
    return (
        <div className='home'>
            <Navbar />
            <About />
            <Mainnet />
            <Testnet />
            <PublicServices />
            <Relayer />
            <Footer />
        </div>
    )
}
export default Home