import { RelayerList } from "../../data/relayer"
import './relayer.sass'

const Relayer = () => {
    return (
        <section className='relayer' id='relayer'>
            <h2 className='relayer__title'>relayer</h2>
            <div className='relayer__container'>
                {RelayerList.map((wallet) => (
                    <div className='relayer__container__card' key={wallet.id}>
                        <h3 className='relayer__container__card__title'>{wallet.name}</h3>
                        <ul className='relayer__container__card__list'>
                            {wallet.wallets.map((addr, index) => (
                                <li className='relayer__container__card__list__wallet' key={index}>
                                    <a href={addr.link} target='_blank' rel="noopener noreferrer" className='relayer__container__card__list__wallet__link'>
                                        <img src={addr.ico} alt=""  className='relayer__container__card__list__wallet__link__img'/>
                                        <p className='relayer__container__card__list__wallet__link__para'>{addr.addr}</p>
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                ))}
            </div>
            <p className='relayer__para'>You can find a detailed description of our relayer infrastructure on our <a href="https://github.com/Inter-Blockchain-Service/Relayers" target="_blank" rel="noopener noreferrer">github</a>.</p>
        </section>
    )
}
export default Relayer