
import React, { useState } from 'react';
import { PublicServicesList } from '../../data/publicServices'
import restake from '../../assets/img/restake.png'
import './publicServices.sass'


const PublicServices = () => {
    const [openMenu, setOpenMenu] = useState([false, false, false, false])
    const toggle = (index) => {
        setOpenMenu((prevState) => {
            const updatedStates = [...prevState]
            updatedStates[index] = !updatedStates[index]
            return updatedStates
        })
    }
    const handleDocumentClick = (event) => {
        // Vérifiez si le clic est en dehors de tous les menus ou des boutons de menu
        const isOutsideMenus = !event.target.closest('.services__container__card__menu')
        const isOutsideButtons = !event.target.classList.contains('services__container__card__button')
        if (isOutsideMenus && isOutsideButtons) {
            setOpenMenu((prevState) => prevState.map(() => false))
        }
    }
    // Attachez le gestionnaire d'événements de clic au document lors du chargement du composant
    React.useEffect(() => {
        document.addEventListener('click', handleDocumentClick)
        // Retirez le gestionnaire d'événements lors du démontage du composant
        return () => {
            document.removeEventListener('click', handleDocumentClick)
        }
    }, [])
    return (
        <section className="services" id="services">
            <h2 className="services__title">public services</h2>
            <div className='services__container'>
                {PublicServicesList.map((service, index) => (
                    <div className='services__container__card' key={service.id}>
                        <img className='services__container__card__img' src={service.ico} alt="" />
                        <h3 className='services__container__card__title'>{service.category}</h3>
                        <button className='services__container__card__button' onClick={() => toggle(index)}>network <i className="fa-solid fa-caret-down"></i></button>
                        <ul className={openMenu[index] ? 'services__container__card__menu open' : 'services__container__card__menu close'} key={service.id + index}>
                            {service.all.map((subService, index) => (
                                <li className='services__container__card__menu__list' key={service.id + index}>
                                    <a href={subService.link} target='_blank' rel="noopener noreferrer" className='services__container__card__menu__list__link'>
                                        <img src={subService.ico} alt="" className='services__container__card__menu__list__link__img' />
                                        {subService.name}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                ))}
            </div>
            <img src={restake} className='services__subtitle' alt='restake.app'></img>
            <p className='services__para'>We run Restake service for all the chains which support Authz, this service allows the autocompund of your rewards, and we support the fees of the transaction for you.<br />
Shootout to <a href='https://www.ecostake.com/' target='_blank' rel="noopener noreferrer">ECOSTAKE</a> for their great work, and the maintenance of their application that allows us to offer this service.</p>
            <a className='services__button' href='https://restake.app/' target='_blank' rel="noopener noreferrer">restake.app</a>
        </section>
    )
}
export default PublicServices