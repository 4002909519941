import ibs from '../../assets/IBS_VERT.png'
import './about.sass'

const About = () => {
    return (
        <section id='about' className='about'>
            <img className='about__logo' src={ibs} alt="" />
            <h1 className='about__title'>Inter Blockchain Services provides trusted validator services, relayer infrastructure, snapshot, statesync, API and RPC for cosmos ecosystem.</h1>
            <p className='about__para'>
                Our validators have 5% commissions that allow us to provide high quality services and paid relayers fees.<br />
                All ours servers are monitored 24/7 with alerting system.<br />
                Our main server is hosted in France by a partner, our relayer infrastructure is self hosted with gigabit fiber connection and we rent several servers from differents providers for redundancy.<br />
                You can join us on our <a href='https://discord.gg/Tyn2xTj2c9' target='_blank' rel="noopener noreferrer"><span>discord</span></a> or by <a href="mailto:contact@ibs.team"><span>mail</span></a>. <br />
            </p>
        </section>
    )
}
export default About