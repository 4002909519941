import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.sass'
import Home from './pages/home/home'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Home />
  </React.StrictMode>
)
