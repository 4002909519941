import { useEffect, useState } from 'react'
import { mainnetList } from '../../data/mainnet.js'
import './mainnet.sass'

const Mainnet = () => {
    const [data, setData] = useState({})
    async function fetchData(url) {
        try {
            const response = await fetch(url)
            if (response.ok) {
                const result = await response.json()
                return result
            } else {
                console.error('Erreur lors de la requête fetch :', response.status, response.statusText)
                return null
            }
        } catch (error) {
            console.error('Une erreur s\'est produite lors de la requête fetch :', error)
            return null
        }
    }
    useEffect(() => {
        // Charger les données au chargement de la page
        const fetchDataPromises = mainnetList.map((e) => fetchData(e.addr))
        Promise.all(fetchDataPromises)
            .then((results) => {
                // Traiter les résultats ici
                const newData = {}
                results.forEach((result, index) => {
                    newData[`data${index}`] = result
                })
                setData(newData)
            })
    }, [])

    return (
        <section className='mainnet' id='mainnet'>
            <h2 className='mainnet__title'>mainnet</h2>
            <div className='mainnet__container'>
                {mainnetList.map((e, index) => (
                    <div className='mainnet__container__card' key={e.id}>
                        <img className='mainnet__container__card__ico' src={e.ico} alt={e.name + 'icone'} />
                        <h3 className='mainnet__container__card__title'>{e.name}</h3>
                        {data[`data${index}`] ? (
                        <div className='mainnet__container__card__infos'>
                            {data[`data${index}`].validator.status === 'BOND_STATUS_BONDED' ? (
                                <div className='mainnet__container__card__infos__line'>
                                    <p>Status: <span style={{color: 'green',fontWeight: 'bold',textTransform: 'uppercase'}}>Online</span></p>
                                    <p>APR: ≃{e.apr}%</p>
                                </div>
                            ) : (
                                <p>Status: <span style={{color: 'red',fontWeight: 'bold',textTransform: 'uppercase'}}>Offline</span></p>
                            )}
                            <p>Delegate Tokens: {Math.floor(parseFloat(data[`data${index}`].validator.tokens) / e.calc).toLocaleString('de-DE') + ' '} <span className={e.token + ' token'}>{e.token}</span></p>
                        </div>
                        ) : (
                        <div className='mainnet__container__card__infos'>
                            <p>Data not available</p>
                        </div>
                        )}
                        <div className='mainnet__container__card__links'>
                            <a href={e.stake} target='_blank' rel="noopener noreferrer">stake</a>
                            <a href={e.explore} target='_blank' rel="noopener noreferrer">explorer</a>
                        </div>
                    </div>
                ))}
            </div>
        </section>
    )
}
export default Mainnet